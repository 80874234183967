import React from "react";
import { Card, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import Event from "../models/Event";
import CustomFieldValue from "../models/CustomFieldValue";
import "./msmVicharanStyles.css";

export interface MsmVicharanEventRendererProps {
    events: Event[],
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event,
        sevaAmount?: number) => void;
}

export default function MsmVicharanEventRenderer(props: MsmVicharanEventRendererProps) {

    const onSevaSelected = (sevaId: string, checked: boolean) => {
        const matchingEventIndex = props.events.findIndex(e => e.name === sevaId);

        if (matchingEventIndex === -1) {
            alert("Invalid Seva Selected");
            return;
        }

        props.updateSevaDetailsForEvent(checked === true ? 1 : 0, [], props.events[matchingEventIndex]);
    }

    const renderSevaLabel = (label: string) => (<span>&nbsp;{label}</span>);

    const renderYajmanSeva = () => {
        return (<Container>
            <Row style={{ paddingBottom: "10px" }}>
                <Col sm="12" md="12">
                    <Card>
                        <Card.Body className="eventName" style={{ textAlign: "center" }}>Yajman Seva</Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="2"></Col>
                <Col sm="12" md="4">
                    <Card className="card">
                        <Card.Img variant="top" src="msm-vicharan/shilanyas.jpg" />
                        <Card.Body>
                            <Card.Title className="eventName">Shilanyash Vidhi <br /> Sydney</Card.Title>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>For Diamond/Platinium/Gold/Silver</ListGroup.Item>
                            <ListGroup.Item>Please contact central Account at</ListGroup.Item>
                            <ListGroup.Item>fundraising@au.baps.org OR</ListGroup.Item>
                            <ListGroup.Item>0430272660</ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox"
                                    onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)}
                                    name="Shilanyash-Vidhi-Bronze"
                                    value="Shilanyash-Vidhi-Bronze"
                                    label={(<span>&nbsp;Bronze $15,000</span>)}>
                                </Form.Check>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col sm="12" md="4">
                    <Card className="card">
                        <Card.Img variant="top" src="msm-vicharan/bhumi_pujan.jpg" />
                        <Card.Body>
                            <Card.Title className="eventName">Akshardham Bhumi Pujan <br /> Melbourne</Card.Title>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                                <Form.Check type="checkbox" onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)} name="Akshardham-Bhumi-Pujan" value="Akshardham-Bhumi-Pujan-Diamond" label={renderSevaLabel("Diamond $151,000.00")}>
                                </Form.Check>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)} name="Akshardham-Bhumi-Pujan" value="Akshardham-Bhumi-Pujan-Platinium" label={renderSevaLabel("Platinium $101,000")}>
                                </Form.Check>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)} name="Akshardham-Bhumi-Pujan" value="Akshardham-Bhumi-Pujan-Gold" label={renderSevaLabel("Gold $75,000")}>
                                </Form.Check>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)} name="Akshardham-Bhumi-Pujan" value="Akshardham-Bhumi-Pujan-Silver" label={renderSevaLabel("Silver $31,000")}>
                                </Form.Check>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)} name="Akshardham-Bhumi-Pujan" value="Akshardham-Bhumi-Pujan-Bronze" label={renderSevaLabel("Bronze $15,000")}>
                                </Form.Check>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col md="2"></Col>
            </Row>
        </Container>);
    }

    const renderCard = (imagePath: string, eventName: string, eventLoc: string, eventId: string, sevaAmount: string, isEventNameLong: boolean) => {
        return (
            <Card className="card">
                <Card.Img variant="top" src={imagePath} />
                <Card.Body>
                    <Card.Title className={isEventNameLong ? "longEventName" : "eventName"}>{eventName} <br /> {eventLoc}</Card.Title>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                        <Form.Check type="checkbox"
                            onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)}
                            value={eventId}
                            name={eventId}
                            label={(<span>&nbsp;${sevaAmount}</span>)}>
                        </Form.Check>
                    </ListGroup.Item>
                </ListGroup>
            </Card>);
    }

    const renderMahotsavSabhaSponsorship = () => {
        return (<Container>
            <Row style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                <Col sm="12" md="12">
                    <Card>
                        <Card.Body className="eventName" style={{ textAlign: "center" }}>Mahotsav Sabha Sponsorship</Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/bhumi_pujan.jpg", " Akshardham Bhoomi Pujan", "Melbourne", "Akshardham-Bhoomi-Pujan-Sabha-Sponsorship", "15,000", true)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/tapomurti_nilkanth.jpg", "Tapomuri Shri Nilkanth Varni Pratistha", "Sydney", "Tapomuri-Shri-Nilkanth-Varni-Pratistha-Sponsorship", "15,000", true)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/murti_pratishtha.jpg", "Shikharbaddh Mandir Murti Pratistha", "Sydney", "Shikharbaddh-Mandir-Murti-Pratistha-Sponsorship", "15,000", true)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/shilanyas.jpg", "Shilanyash Vidhi", "Sydney", "Shilanyash-Vidhi-Sabha-Sponsorship", "15000", false)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/fuldol.jpg", "Fuldol", "Sydney", "Fuldol-Sabha-Sponsorship", "15,000", false)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/maha_annakut.jpg", "Maha Annakut", "Sydney", "Maha-Annakut-Sabha-Sponsorship", "15,000", false)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/vishwa_shanti.jpg", "Shri Swaminarayan Vishvashanti Mahayagna", "Sydney", "Shri-Swaminarayan-Vishvashanti-Mahayagna-Sponsorship", "15,000", true)}
                </Col>
            </Row>
        </Container>);
    }

    const renderUtsavSabhaSponsorship = () => {
        return (<Container>
            <Row style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                <Col sm="12" md="12">
                    <Card>
                        <Card.Body className="eventName" style={{ textAlign: "center" }}>Utsav Sabha Sponsorship</Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/swagat_sabha.jpg", "Swagat Sabha", "Sydney", "Swagat-Sabha-Sydney-Sponsorship", "10,000", false)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/swagat_sabha.jpg", "Swagat Sabha", "Melbourne", "Swagat-Sabha-Melbourne-Sponsorship", "10,000", false)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/swagat_sabha.jpg", "Satsang Diksha Janomtsav", "Melbourne", "Satsang-Diksha-Janomtsav-Sponsorship", "10,000", true)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/diksha_mahotsav.jpg", "Diksha Mahotsav", "Melbourne", "Diksha-Mahotsav-Sponsorship", "10,000", false)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/swagat_sabha.jpg", "Guruhari Bhakti Tula", "Melbourne", "Bhakti-Tula-Sponsorship", "10,000", true)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/nakkar_vidwan.jpg", "Nakkar Vidvan Grand Falicitation", "Melbourne", "Nakkar-Vidvan-Grand-Falicitation-Sponsorship", "10,000", true)}
                </Col>
                <Col sm="12" md="3">
                    {renderCard("msm-vicharan/nagar_yatra.jpg", "Nagar Yatra", "Melbourne", "Nagar-Yatra-Sponsorship", "10,000", false)}
                </Col>
            </Row>
        </Container>);
    }

    const murtiPratishthaSevaItems = [{ "SevaId": "Sydney-Shikharbaddh-Tapomurti-Shri-Nilkanth-Varni-Maharaj", "SevaName": "Tapomurti Shri Nilkanth Varni Maharaj", "SevaAmount": "51,000" },
    { "SevaId": "Sydney-Shikharbaddh-Shri-Nilkanthvarni-Abhishek-Murti", "SevaName": "Shri Nilkanthvarni Abhishek Murti", "SevaAmount": "11,000" },
    { "SevaId": "Sydney-Shikharbaddh-Sinhasan-Seva", "SevaName": "Sinhasan Seva", "SevaAmount": "25,000" },
    { "SevaId": "Sydney-Shikharbaddh-Shri-Akshar-Purushottam-Maharaj-Murti-Seva", "SevaName": "Shri Akshar Purushottam Maharaj Murti Seva ", "SevaAmount": "21,000" },
    { "SevaId": "Sydney-Shikharbaddh-Shri-Ghanshayam-Maharaj-Murti-Seva", "SevaName": "Shri Ghanshayam Maharaj Murti Seva", "SevaAmount": "15,000" },
    { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Ram-Parivar", "SevaName": "Bhagvan Shri Ram Parivar", "SevaAmount": "15,000" },
    { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Shankar-Parivar", "SevaName": "Bhagvan Shri Shankar Parivar", "SevaAmount": "15,000" },
    { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Krishna-and-Shri-Radhikaji", "SevaName": "Bhagvan Shri Krishna and Shri Radhikaji", "SevaAmount": "15,000" }];

    const renderSydneyShikharbadhMandirMurtiPratisthaSeva = () => {
        return (<Container>
            <Row style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                <Col sm="12" md="12">
                    <Card>
                        <Card.Body className="eventName" style={{ textAlign: "center" }}>Sydney Shikharbaddh Mandir Murti Pratistha Seva</Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                <Col sm="12" md="12">
                    <ListGroup className="list-group-flush">
                        {murtiPratishthaSevaItems.map(item => {
                            return (<ListGroup.Item>
                                <Form.Check type="checkbox"
                                    onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)}
                                    name={item.SevaId}
                                    value={item.SevaId}
                                    label={(<span>&nbsp;&nbsp;{`${item.SevaName} $${item.SevaAmount}`}</span>)}>
                                </Form.Check>
                            </ListGroup.Item>);
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Container>);
    }

    const miscSevaItems = [
        { "SevaId": "Sant-Rasoi", "SevaName": "Sant Rasoi / Day", "SevaAmount": "1,000" },
        { "SevaId": "Akshar-Purushottam-Maharaj-Thal", "SevaName": "Akshar Purushottam Maharaj Thal / Day", "SevaAmount": "1,000" },
        { "SevaId": "Param-Pujya-Mahant-Swami-Dhotiya-Seva", "SevaName": "Dhotiya Seva - P.P. Mahant Swami Maharaj", "SevaAmount": "500" },
        { "SevaId": "Param-Pujya-Mahant-Swami-And-All-Santo-Dhotiya-Seva", "SevaName": "Dhotiya Seva - P.P. Mahant Swami Maharaj and all Vicharan Santo", "SevaAmount": "5,000" }];

    const renderOtherMiscSeva = () => {
        return (<Container>
            <Row style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                <Col sm="12" md="12">
                    <Card>
                        <Card.Body className="eventName" style={{ textAlign: "center" }}>Other Miscellaneous Seva</Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                <Col sm="12" md="12">
                    <ListGroup className="list-group-flush">
                        {miscSevaItems.map(item => {
                            return (<ListGroup.Item>
                                <Form.Check type="checkbox"
                                    onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)}
                                    name={item.SevaId}
                                    value={item.SevaId}
                                    label={(<span>&nbsp;&nbsp;{`${item.SevaName} $${item.SevaAmount}`}</span>)}>
                                </Form.Check>
                            </ListGroup.Item>);
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Container>);
    }

    return (<div id="sevacardcontainers">
        {renderYajmanSeva()}
        {/*renderMahotsavSabhaSponsorship()*/}
        {/*renderUtsavSabhaSponsorship()*/}
        {renderSydneyShikharbadhMandirMurtiPratisthaSeva()}
        {/*renderOtherMiscSeva()*/}
    </div>);
}