
enum Centre {
    Adelaide = 1,
    Brisbane = 2,
    Canberra = 3,
    Darwin = 4,
    GoldCoast = 5,
    MelbourneMandir = 6,
    MelbourneWest = 7,
    MelbourneSouth = 8,
    MelbourneEast = 29,
    Perth = 9,
    Hobart = 10,
    Sydney = 11,
    SunshineCoast = 30,
    Griffith = 12,
    Geelong = 13,
    Newcastle = 14,
    Shepparton = 15,
    Albury = 16,
    PerthMandir = 17,
    PerthSouth = 18,
    Auckland = 19,
    Wellington = 20,
    Christchurch = 21,
    BrisbaneNorth = 22,
    BrisbaneSouth = 23,
    Toowoomba = 24,
    Balwyn = 25,
    Cranbourne = 26,
    Ballarat = 27,
    Bendigo = 28,
    Tamworth = 31,
    Gosford = 32,
    Dubbo = 33,
    Mittagong = 34,
    Gatton = 35,
    Carins = 36,
    Townsville = 37
}

export default Centre;
