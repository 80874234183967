import React from "react";
import { Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

interface TopNavProps {
    eventGroupName: string,
    match: any;
    location: any;
    history: any;
}

export function TopNav(props: TopNavProps) {

    let rootPath = "/";
    let headerImgName = "header.jpeg";

    const chagePageStyle = (eventGroupName: string) => {
        if(eventGroupName === "canberra-mandir-murti-pratistha") {
            document.body.style.backgroundColor = "#4D0303";
            document.body.style.color = "white";
        }
    }

    const getRedirectPath = (formattedEventGroupName: string) => {
        return formattedEventGroupName === "canberra-mandir-murti-pratistha" ?
        "https://cbr.bapsanz.org/" : `/${formattedEventGroupName}`;
    }

    if (props.eventGroupName) {
        const formattedEventGroupName = props.eventGroupName.toLocaleLowerCase();
        chagePageStyle(formattedEventGroupName);
        rootPath = getRedirectPath(formattedEventGroupName);
        headerImgName = `${formattedEventGroupName}.jpeg`;
    }

    const shouldRenderTopNav = (eventGroupName: String) => {
        return !isReportsPage();
    }

    const isReportsPage = () => props.location.pathname.indexOf("reports") !== -1 ||
                                props.location.pathname.indexOf("token-mgmt") !== -1;

    const renderTopNav = () => {
        return (<div className="headerContainer">
            <Navbar style={{ padding: 0 }}>
                <Navbar.Brand href={`${rootPath}`}>
                    <img src={`/${headerImgName}`} width="100%" />
                </Navbar.Brand>
            </Navbar>
        </div>);
    }

    return (shouldRenderTopNav(props.eventGroupName) ? renderTopNav() : <React.Fragment />);
}

const mapStateToProps = (state: any) => {
    return {
        eventGroupName: state.uiState.eventGroupName
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));