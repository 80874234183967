import React from "react";
import { Switch, Route } from "react-router-dom";
import AcquireDonorDetails from "./AcquireDonorDetails";
import ConfirmDonationDetails  from "./ConfirmDonationDetails";
import AcquireDonationDetails from "./AcquireDonationDetails";
import ReportLogin from "./reports/ReportsLogin";
import Reports from "./reports/Reports";
import GujaratiClassRegistration from "./GujaratiClassRegistration";
import SydneyMandirDonations from "./SydneyMandirDonations";
import SquareOrderConfirmation from "./SquareOrderConfirmation";
import NilkanthVarniMurtiPratistha from "./NilkanthVarniMurtiPratistha";
import KidsDiwaliRegistration from "./KidsDiwaliRegistration";
import KidsDiwaliCheckin from "./KidsDiwaliCheckin";
import DonationBoxAuthTokenRefresh from "./reports/DonationBoxAuthTokenRefresh";

export default function Routes() {
    return (<Switch>
        <Route path="/donation-details" exact component={AcquireDonationDetails} />
        <Route path="/confirm" exact component={ConfirmDonationDetails} />
        <Route path="/reports-login" exact component={ReportLogin} />
        <Route path="/reports" exact component={Reports} />
        <Route path="/token-mgmt" exact component={DonationBoxAuthTokenRefresh} />
        <Route path="/gujarati-class-registration" exact component={GujaratiClassRegistration} />
        <Route path="/nilkant-varni-murti-prathishtah" exact component={NilkanthVarniMurtiPratistha} />
        <Route path="/sydney-mandir-donations" exact component={SydneyMandirDonations} />
        <Route path="/square-order-confirmation" exact component={SquareOrderConfirmation} />
        <Route path="/sydney-kids-diwali-2024" exact component={KidsDiwaliRegistration} />
        <Route path="/kids-diwali-checkin" exact component={KidsDiwaliCheckin} />
        <Route path="/:eventgroup?" exact component={AcquireDonorDetails} />
    </Switch>)
}
