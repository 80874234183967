import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";
import { auth0ReportApiAudience } from "../AppSettings";
import ReportsLogin from "./ReportsLogin";

export default function DonationBoxAuthTokenRefresh() {

    debugger;
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const [accessToken, setAccessToken] = useState<string>();

    const [refreshInProgress, setRefreshInProgress] = useState<boolean>(false);
    const [errorInRefresh, setErrorInRefresh] = useState<string>("");
    const [isRefreshSuccess, setIsRefreshSuccess] = useState<boolean | null>(null);

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            retrieveUserToken();
        }
    }, [isLoading, isAuthenticated]);

    const retrieveUserToken = async () => {
        const token = await getAccessTokenSilently({
            audience: auth0ReportApiAudience
        });

        setAccessToken(token);
    }

    const onRefreshClick = () => {
    }

    const renderRefreshToken = () => {
        return (<>
            <Button variant="primary" onClick={() => onRefreshClick()}>Refresh Auth Token</Button>
        </>);
    }

    return (isAuthenticated ? renderRefreshToken() : <ReportsLogin />);
}